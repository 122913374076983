export const environment = {
  production: false,
  SupportEmailId: 'digital.support@diversey.com',
  MAPS_API_URL: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  MAPS_TIMEZONE_API_URL: 'https://maps.googleapis.com/maps/api/timezone/json?location=',
  MAPS_TIMEZONE_API_KEY: 'AIzaSyAR2zqTfKGaUCkvELCYOiOAPWxJkUDFESg',
  NODE_URL: 'https://dev-pac.diverseyiot.com/api/v1/',
  DASHBOARD_HOST_URL: 'https://dev-dashboard.diverseyiot.com/',
  PAC_HOST_URL: 'https://dev-pac.diverseyiot.com/',
  COOKIE_DOMAIN_NAME: '.diverseyiot.com',
  OKTA_COOKIE_DOMAIN_NAME: 'dev-dashboard.diverseyiot.com',
  COOKIE_PREFIX: 'dev_',
  TERMS_CONDITIONS: 'https://www.diversey.com/ioc-terms-and-conditions',
  DOMAIN_PREFIX: 'dev-',
  OKTA_DOMAIN: 'https://diversey.oktapreview.com',
  OKTA_ISSUER: 'https://diversey.oktapreview.com/oauth2/default',
  OKTA_CLIENT_ID: '0oa1f2zyl91siKkhs0h8'
};
